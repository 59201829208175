.brk-shop-grid-filter__button_style-3, .brk-shop-grid-filter-grid {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.brk-shop-grid-filter {
  /* BUTTONS */
  /* BUTTONS */
  /* ITEMS */
  /* ITEMS */ }
  .brk-shop-grid-filter__button {
    position: relative;
    /* brk-shop-grid-filter__button_style-1 */
    /* brk-shop-grid-filter__button_style-2 */
    /* brk-shop-grid-filter__button_style-3 */ }
    .brk-shop-grid-filter__button li {
      position: relative;
      z-index: 1;
      cursor: pointer; }
      .brk-shop-grid-filter__button li i {
        vertical-align: 0; }
    .brk-shop-grid-filter__button .before {
      content: '';
      position: absolute; }
    .brk-shop-grid-filter__button_style-1 {
      /* brk-shop-grid-filter__button_style-1 */
      background-color: #fafafa;
      min-height: 130px;
      padding: 40px 0 35px 0;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      box-shadow: inset 0 6px 12px rgba(0, 0, 0, 0.02); }
      .brk-shop-grid-filter__button_style-1 li {
        height: 44px;
        border-radius: 24px;
        box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.06);
        font-size: 0.875rem;
        text-align: center;
        overflow: hidden;
        margin: 0 5px;
        padding: 2px;
        display: block;
        font-weight: bold;
        position: relative;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out; }
        @media (max-width: 767px) {
          .brk-shop-grid-filter__button_style-1 li {
            margin: 5px;
            font-weight: normal;
            font-size: 0.875rem; } }
        .brk-shop-grid-filter__button_style-1 li.checked .before {
          height: 100%; }
        .brk-shop-grid-filter__button_style-1 li .brk-shop-grid-filter__button-text {
          padding: 0 37px;
          line-height: 40px;
          border-radius: 20px;
          background-color: #fafafa; }
        .brk-shop-grid-filter__button_style-1 li i {
          position: relative;
          margin-right: 7px; }
      .brk-shop-grid-filter__button_style-1 .before {
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 100%;
        height: 0;
        z-index: -1;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out; }
    .brk-shop-grid-filter__button_style-2 {
      /* brk-shop-grid-filter__button_style-2 */
      min-height: 76px;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      position: relative;
      margin-bottom: 55px; }
      @media (max-width: 991px) {
        .brk-shop-grid-filter__button_style-2 {
          min-height: 1px; } }
      .brk-shop-grid-filter__button_style-2:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 3px;
        z-index: 1;
        background-color: #f4f4f4; }
        @media (max-width: 991px) {
          .brk-shop-grid-filter__button_style-2:before {
            display: none; } }
      .brk-shop-grid-filter__button_style-2 li {
        height: 76px;
        line-height: 76px;
        display: block;
        cursor: pointer;
        font-size: 1rem;
        text-transform: uppercase;
        padding: 0 38px;
        position: relative;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        z-index: 2; }
        @media (max-width: 991px) {
          .brk-shop-grid-filter__button_style-2 li {
            width: 100%;
            height: auto;
            line-height: 40px;
            padding: 0;
            text-align: center; }
            .brk-shop-grid-filter__button_style-2 li:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 3px;
              z-index: 1;
              background-color: #f4f4f4; } }
        .brk-shop-grid-filter__button_style-2 li.checked {
          box-shadow: 0 -2px 14px rgba(0, 0, 0, 0.06);
          background-color: var(--white); }
          @media (max-width: 991px) {
            .brk-shop-grid-filter__button_style-2 li.checked {
              box-shadow: none; } }
          .brk-shop-grid-filter__button_style-2 li.checked .before {
            width: 100%; }
        .brk-shop-grid-filter__button_style-2 li.all {
          position: absolute;
          top: 0;
          right: 0;
          padding: 0 26px; }
          @media (max-width: 991px) {
            .brk-shop-grid-filter__button_style-2 li.all {
              position: relative;
              top: auto;
              right: auto;
              padding: 0; } }
          .brk-shop-grid-filter__button_style-2 li.all i {
            position: relative;
            top: 1px;
            margin-left: 8px; }
      .brk-shop-grid-filter__button_style-2 .before {
        position: absolute;
        left: 50%;
        bottom: 0;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        height: 3px;
        width: 0;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        z-index: 3; }
    .brk-shop-grid-filter__button_style-3 {
      /* brk-shop-grid-filter__button_style-3 */
      height: 100px;
      margin-bottom: 40px; }
      @media (max-width: 991px) {
        .brk-shop-grid-filter__button_style-3 {
          height: auto; } }
      .brk-shop-grid-filter__button_style-3 li {
        width: 100%;
        max-width: 200px;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
                flex-grow: 1;
        height: 70px;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out; }
        @media (max-width: 991px) {
          .brk-shop-grid-filter__button_style-3 li {
            width: -webkit-calc(100% - 30px);
            width: calc(100% - 30px);
            max-width: 9999px;
            height: 40px;
            -webkit-box-flex: 0;
            -webkit-flex-grow: 0;
                    flex-grow: 0; } }
        .brk-shop-grid-filter__button_style-3 li:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.86)), to(rgba(0, 0, 0, 0.43)));
          background-image: linear-gradient(to top, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.43)); }
        .brk-shop-grid-filter__button_style-3 li.checked {
          height: 100px; }
          @media (max-width: 991px) {
            .brk-shop-grid-filter__button_style-3 li.checked {
              height: 40px;
              width: 100%; } }
          .brk-shop-grid-filter__button_style-3 li.checked:before {
            background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.07)));
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.07)); }
          .brk-shop-grid-filter__button_style-3 li.checked .before {
            height: 3px; }
      .brk-shop-grid-filter__button_style-3 .text {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 5px 13px 19px;
        z-index: 4;
        font-size: 1rem;
        line-height: 18px; }
        @media (max-width: 991px) {
          .brk-shop-grid-filter__button_style-3 .text {
            font-size: 0.875rem;
            line-height: 16px;
            padding: 0 15px;
            bottom: auto;
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%); } }
      .brk-shop-grid-filter__button_style-3 .before {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 0;
        width: 100%;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        z-index: 3; }
  .brk-shop-grid-filter-grid {
    min-height: 480px;
    position: relative;
    overflow: hidden; }
    .brk-shop-grid-filter-grid:hover:before {
      opacity: 0; }
    .brk-shop-grid-filter-grid:hover:after {
      opacity: 1; }
    .brk-shop-grid-filter-grid:hover .brk-shop-grid-filter-grid__content {
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .brk-shop-grid-filter-grid:hover .brk-shop-grid-filter-grid__stick {
      left: -75px; }
    .brk-shop-grid-filter-grid:before, .brk-shop-grid-filter-grid:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .brk-shop-grid-filter-grid:before {
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.2)));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%); }
    .brk-shop-grid-filter-grid:after {
      opacity: 0;
      background: rgba(0, 0, 0, 0.7); }
    .brk-shop-grid-filter-grid__content {
      position: absolute;
      width: 100%;
      max-width: 330px;
      padding: 0 15px;
      left: 50%;
      top: -webkit-calc(100% - 90px);
      top: calc(100% - 90px);
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      z-index: 2;
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out; }
      .brk-shop-grid-filter-grid__content h4 {
        min-height: 56px; }
      .brk-shop-grid-filter-grid__content ul li {
        padding: 5px 0; }
        .brk-shop-grid-filter-grid__content ul li i {
          margin-top: 4px;
          margin-right: 19px; }
    .brk-shop-grid-filter-grid__stick {
      position: absolute;
      left: 40px;
      top: 45px;
      z-index: 2;
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out; }
      .brk-shop-grid-filter-grid__stick span {
        display: block;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        position: relative;
        text-align: center;
        margin-top: -14px;
        line-height: 72px;
        text-transform: uppercase; }
    .brk-shop-grid-filter-grid__buy {
      width: 100%;
      max-width: 239px;
      height: 48px;
      display: block;
      border-radius: 24px;
      position: relative; }
      .brk-shop-grid-filter-grid__buy-add {
        display: block;
        margin-right: 94px;
        padding-left: 25px; }
        .brk-shop-grid-filter-grid__buy-add i {
          vertical-align: 0;
          margin-right: 18px; }
      .brk-shop-grid-filter-grid__buy .added_to_cart {
        margin: 10px 0 0;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 0.875rem; }
      .brk-shop-grid-filter-grid__buy .price {
        position: absolute;
        display: block;
        top: 50%;
        right: 3px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 93px;
        height: 42px;
        border-radius: 21px; }
  .brk-shop-grid-filter-cells {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    /* Components */
    /* Components */ }
    .brk-shop-grid-filter-cells:hover:before,
    .brk-shop-grid-filter-cells:hover .before {
      height: 100%; }
    .brk-shop-grid-filter-cells:hover .brk-shop-grid-filter-cells__content {
      bottom: 0 !important; }
    .brk-shop-grid-filter-cells:hover .brk-shop-grid-filter-cells__stick {
      opacity: 1; }
    .brk-shop-grid-filter-cells:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 100%;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      z-index: 1;
      background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.27)), to(rgba(0, 0, 0, 0.45)));
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.27) 0%, rgba(0, 0, 0, 0.45) 100%); }
    .brk-shop-grid-filter-cells .before {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      width: 101%;
      height: 0;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .brk-shop-grid-filter-cells__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 101%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      -o-object-position: center;
         object-position: center; }
    .brk-shop-grid-filter-cells__content {
      position: absolute;
      bottom: -100%;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 25px;
      z-index: 2;
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out; }
    .brk-shop-grid-filter-cells__stick {
      width: 100%;
      margin: 0 -5px;
      opacity: 0;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
      .brk-shop-grid-filter-cells__stick span {
        display: block;
        height: 20px;
        line-height: 20px;
        padding: 0 11px;
        margin: 0 5px;
        border-radius: 10px; }
    @media (min-width: 1200px) and (max-width: 1499px) {
      .brk-shop-grid-filter-cells__rating {
        width: 100%;
        margin-bottom: 10px; } }
    .brk-shop-grid-filter-cells__rating .brk-rating {
      margin-top: 4px; }
      .brk-shop-grid-filter-cells__rating .brk-rating__layer i, .brk-shop-grid-filter-cells__rating .brk-rating__imposition i {
        color: var(--white); }
    .brk-shop-grid-filter-cells__buy {
      position: relative;
      display: inline-block;
      min-width: 90px;
      height: 38px;
      border-radius: 19px;
      line-height: 38px;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      padding: 0 19px;
      text-align: center;
      border: solid 2px var(--white); }
      .brk-shop-grid-filter-cells__buy:hover {
        background-color: var(--white); }
        .brk-shop-grid-filter-cells__buy:hover .text {
          opacity: 1; }
      .brk-shop-grid-filter-cells__buy .text {
        position: absolute;
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 100%;
        display: inline-block;
        -webkit-transition: all .3s ease-in-out;
        transition: all .3s ease-in-out;
        opacity: 0; }
        .brk-shop-grid-filter-cells__buy .text i {
          position: relative;
          top: -2px;
          margin-right: 3px;
          vertical-align: middle; }
    .brk-shop-grid-filter-cells .added_to_cart {
      padding: 7px 15px;
      font-size: 0.875rem;
      font-weight: normal;
      margin: 10px 0; }
  .brk-shop-grid-filter-strict {
    width: 100%;
    max-width: 370px;
    margin: 15px auto;
    position: relative;
    min-height: 470px;
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.14);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: var(--white);
    /* Components */
    /* Components */ }
    .brk-shop-grid-filter-strict:hover {
      -webkit-transform: translate(15px, 15px);
              transform: translate(15px, 15px); }
      .brk-shop-grid-filter-strict:hover .before {
        opacity: 1; }
      .brk-shop-grid-filter-strict:hover .brk-shop-grid-filter-strict__back {
        -webkit-transform: translate(-30px, -30px);
                transform: translate(-30px, -30px);
        opacity: 1;
        z-index: 9; }
      .brk-shop-grid-filter-strict:hover .brk-shop-grid-filter-strict__stick {
        opacity: 0; }
    .brk-shop-grid-filter-strict .before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      z-index: 1; }
    .brk-shop-grid-filter-strict__back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 6px 40px rgba(0, 0, 0, 0.2);
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      opacity: 0;
      z-index: -1;
      background-color: var(--white); }
    .brk-shop-grid-filter-strict__thumb {
      height: 370px;
      position: relative;
      text-align: center;
      background-color: #f2f2f2; }
      .brk-shop-grid-filter-strict__thumb img {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%; }
    .brk-shop-grid-filter-strict__inform {
      height: 100px;
      padding: 0 15px; }
      .brk-shop-grid-filter-strict__inform .price {
        margin-top: 3px; }
        .brk-shop-grid-filter-strict__back .brk-shop-grid-filter-strict__inform .price {
          margin-top: 7px; }
      .brk-shop-grid-filter-strict__inform .old-price {
        text-decoration: line-through;
        padding-right: 13px; }
      .brk-shop-grid-filter-strict__back .brk-shop-grid-filter-strict__inform {
        border-bottom: 1px solid rgba(var(--brk-dark-base-rgb), 0.1);
        height: 115px; }
    .brk-shop-grid-filter-strict__stick {
      position: absolute;
      right: -6px;
      top: 25px;
      width: 70px;
      height: 34px;
      font-size: 0.875rem;
      line-height: 34px;
      text-align: center;
      padding-left: 11px;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      z-index: 9; }
      .brk-shop-grid-filter-strict__stick:before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 14% 50%);
                clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 14% 50%);
        background-color: var(--brand-primary); }
      .brk-shop-grid-filter-strict__stick:after {
        content: '';
        position: absolute;
        bottom: -6px;
        right: 0;
        width: 0;
        height: 0;
        border: 3px solid transparent;
        z-index: 1;
        border-left-color: var(--brk-base-3);
        border-top-color: var(--brk-base-3); }
    .brk-shop-grid-filter-strict__list {
      -webkit-box-align: center;
      -webkit-align-items: center;
              align-items: center;
      overflow-y: auto;
      max-height: 225px;
      border-bottom: 1px solid rgba(var(--brk-dark-base-rgb), 0.1); }
      .brk-shop-grid-filter-strict__list::-webkit-scrollbar {
        width: 7px; }
      .brk-shop-grid-filter-strict__list::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px; }
      .brk-shop-grid-filter-strict__list::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px; }
      .brk-shop-grid-filter-strict__list ul,
      .brk-shop-grid-filter-strict__list p {
        max-height: 100%;
        width: 100%;
        max-width: 260px;
        margin: 0 auto; }
      .brk-shop-grid-filter-strict__list li {
        padding: 5px 0; }
      .brk-shop-grid-filter-strict__list i {
        font-weight: 100;
        padding-top: 5px;
        padding-right: 23px; }
        @media screen and (max-width: 479px) {
          .brk-shop-grid-filter-strict__list i {
            padding-right: 10px; } }
    .brk-shop-grid-filter-strict__actions {
      display: inline-block;
      border-radius: 27px; }
      .brk-shop-grid-filter-strict__actions a {
        float: left;
        display: block;
        width: 72px;
        height: 54px;
        line-height: 48px;
        text-align: center;
        border-left: 1px solid var(--brand-primary);
        border-right: 1px solid var(--brand-primary); }
        .brk-shop-grid-filter-strict__actions a i {
          vertical-align: middle; }
        .brk-shop-grid-filter-strict__actions a:first-child {
          border-radius: 27px 0 0 27px;
          border: 0; }
        .brk-shop-grid-filter-strict__actions a:last-child {
          border-radius: 0 27px 27px 0;
          border: 0; }
        .brk-shop-grid-filter-strict__actions a.added_to_cart {
          font-size: 0.875rem;
          font-weight: normal;
          line-height: 16px;
          padding: 10px 5px 0;
          width: 72px;
          margin: 0;
          white-space: normal;
          border-radius: 0;
          background-color: #105ae0;
          color: #fff;
          box-shadow: none; }
        .brk-shop-grid-filter-strict__actions a.remove_from_wishlist, .brk-shop-grid-filter-strict__actions a.remove_from_compare {
          background-color: #375cf1; }
  .brk-shop-grid-filter-special {
    height: 0;
    padding-bottom: 100%;
    position: relative;
    max-height: 300px;
    /* Components */
    /* Components */ }
    .brk-shop-grid-filter-special__overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .brk-shop-grid-filter-special:hover .brk-shop-grid-filter-special__overlay::before {
      opacity: 0; }
    .brk-shop-grid-filter-special:hover .brk-shop-grid-filter-special__overlay::after {
      opacity: 1; }
    .brk-shop-grid-filter-special:hover .brk-shop-grid-filter-special__content {
      top: 50%; }
    .brk-shop-grid-filter-special__overlay::before, .brk-shop-grid-filter-special__overlay::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 101%;
      height: 100%;
      z-index: 2;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out; }
    .brk-shop-grid-filter-special__overlay::before {
      background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.69)), color-stop(50%, rgba(0, 0, 0, 0)));
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.69), rgba(0, 0, 0, 0) 50%); }
    .brk-shop-grid-filter-special__overlay::after {
      background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.86)), color-stop(99%, rgba(0, 0, 0, 0.69)));
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.86), rgba(0, 0, 0, 0.69) 99%);
      opacity: 0; }
    .brk-shop-grid-filter-special__container {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .brk-shop-grid-filter-special__thumb {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      z-index: 1; }
    .brk-shop-grid-filter-special__content {
      position: absolute;
      width: 100%;
      max-width: 250px;
      top: -webkit-calc(100% + 35px);
      top: calc(100% + 35px);
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      -webkit-transition: all .4s ease-in-out;
      transition: all .4s ease-in-out;
      z-index: 1; }
      .brk-shop-grid-filter-special__content .old-price {
        text-decoration: line-through;
        margin-right: 5px; }
    .brk-shop-grid-filter-special__title {
      min-height: 50px; }
    .brk-shop-grid-filter-special__buy {
      display: inline-block;
      width: 179px;
      height: 44px;
      border-radius: 22px;
      position: relative; }
      .brk-shop-grid-filter-special__buy .text {
        display: block;
        text-align: left;
        padding-left: 31px; }
      .brk-shop-grid-filter-special__buy .icon {
        position: absolute;
        top: 50%;
        right: 5px;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        line-height: 34px;
        background-color: #fdfdfd; }
      .brk-shop-grid-filter-special__buy i {
        vertical-align: middle; }
    .brk-shop-grid-filter-special .added_to_cart {
      padding: 5px 15px;
      font-weight: normal;
      font-size: 0.875rem;
      margin: 10px 0 0; }
    .brk-shop-grid-filter-special__stick {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 100px;
      height: 100px;
      z-index: 9;
      overflow: hidden; }
      .brk-shop-grid-filter-special__stick .stick {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden; }
        .brk-shop-grid-filter-special__stick .stick:before, .brk-shop-grid-filter-special__stick .stick:after {
          content: '';
          position: absolute;
          z-index: 1;
          width: 5px;
          height: 5px; }
        .brk-shop-grid-filter-special__stick .stick:before {
          top: 0;
          left: 0; }
        .brk-shop-grid-filter-special__stick .stick:after {
          right: 0;
          bottom: 0; }
        .brk-shop-grid-filter-special__stick .stick span {
          display: block;
          position: absolute;
          z-index: 2;
          height: 25px;
          text-align: center;
          font-size: 1rem;
          line-height: 25px;
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
        .brk-shop-grid-filter-special__stick .stick:nth-child(1) {
          width: 66px;
          height: 66px; }
          .brk-shop-grid-filter-special__stick .stick:nth-child(1):before, .brk-shop-grid-filter-special__stick .stick:nth-child(1):after {
            background-color: var(--brk-base-3); }
          .brk-shop-grid-filter-special__stick .stick:nth-child(1) span {
            width: 92px;
            top: -25px;
            left: 0;
            -webkit-transform-origin: 0 100%;
                    transform-origin: 0 100%;
            background-image: -webkit-gradient(linear, right top, left top, from(var(--brk-base-6)), to(var(--brk-base-5)));
            background-image: linear-gradient(to left, var(--brk-base-6), var(--brk-base-5)); }
        .brk-shop-grid-filter-special__stick .stick:nth-child(2) {
          width: 100%;
          height: 100%; }
          .brk-shop-grid-filter-special__stick .stick:nth-child(2):before, .brk-shop-grid-filter-special__stick .stick:nth-child(2):after {
            background-color: var(--brk-secondary-2); }
          .brk-shop-grid-filter-special__stick .stick:nth-child(2) span {
            width: 141px;
            top: -25px;
            left: 0;
            -webkit-transform-origin: 0 100%;
                    transform-origin: 0 100%;
            background-image: -webkit-gradient(linear, left top, right top, from(var(--brk-base-3)), to(var(--brk-secondary-3)));
            background-image: linear-gradient(to right, var(--brk-base-3), var(--brk-secondary-3)); }
