@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.brk-shop-grid-filter {

	/* BUTTONS */
	&__button {
		position: relative;
		
		li {
			position: relative;
			z-index: 1;
			cursor: pointer;

			i {
				vertical-align: 0;
			}
		}

		.before {
			content: '';
			position: absolute;
		}

		&_style-1 { /* brk-shop-grid-filter__button_style-1 */
			background-color: #fafafa; //BASE
      min-height: 130px;
      padding: 40px 0 35px 0;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-wrap: wrap;
			box-shadow: inset 0 6px 12px rgba(0,0,0,.02);

			// @media (max-width: 767px) {
			// 	min-height: 1px;
			// 	padding: 5px;
			// }

			li {
				height: 44px;
				border-radius: 24px;
				box-shadow: inset 0 0 0 2px rgba(0,0,0,.06);
				font-size: rem(14);
				text-align: center;
				overflow: hidden;
				margin: 0 5px;
				padding: 2px;
				display: block;
				font-weight: bold;
				position: relative;
				transition: all .2s ease-in-out;
				
				@media (max-width: 767px) {
					margin: 5px;
					font-weight: normal;
					font-size: rem(14);
				}

				&.checked {

					.before {
						height: 100%;
					}
				}

				.brk-shop-grid-filter__button-text {
					padding: 0 37px;
					line-height: 40px;
					border-radius: 20px;
					background-color: #fafafa; //BASE
				}

				i {
					position: relative;
					margin-right: 7px;
				}
			}

			.before {
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				height: 0;
				z-index: -1;
				transition: all .2s ease-in-out;
			}
		} /* brk-shop-grid-filter__button_style-1 */

		&_style-2 { /* brk-shop-grid-filter__button_style-2 */
			min-height: 76px;
			display: flex;
			flex-wrap: wrap;
			position: relative;
			margin-bottom: 55px;

			@media (max-width: 991px) {
				min-height: 1px;
			}

			&:before {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 3px;
				z-index: 1;
				background-color: #f4f4f4;

				@media (max-width: 991px) {
					display: none;
				}
			}
			
			li {
				height: 76px;
				line-height: 76px;
				display: block;
				cursor: pointer;
				font-size: rem(16);
				text-transform: uppercase;
				padding: 0 38px;
				position: relative;
				transition: all .3s ease-in-out;
				z-index: 2;

				@media (max-width: 991px) {
					width: 100%;
					height: auto;
					line-height: 40px;
					padding: 0;
					text-align: center;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 100%;
						height: 3px;
						z-index: 1;
						background-color: #f4f4f4;
					}
				}

				&.checked {
					box-shadow: 0 -2px 14px rgba(0,0,0,.06);
					background-color: var(--white);

					@media (max-width: 991px) {
						box-shadow: none;
					}

					.before {
						width: 100%;
					}
				}

				&.all {
					position: absolute;
					top: 0;
					right: 0;
					padding: 0 26px;

					@media (max-width: 991px) {
						position: relative;
						top: auto;
						right: auto;
						padding: 0;
					}

					i {
						position: relative;
						top: 1px;
						margin-left: 8px;
					}
				}
			}

			.before {
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				height: 3px;
				width: 0;
				transition: all .3s ease-in-out;
				z-index: 3;
			}
		} /* brk-shop-grid-filter__button_style-2 */

		&_style-3 { /* brk-shop-grid-filter__button_style-3 */
			height: 100px;
			margin-bottom: 40px;
			@extend %bg-style;

			@media (max-width: 991px) {
				height: auto;
			}

			li {
				width: 100%;
				max-width: 200px;
				flex-grow: 1;
				height: 70px;
				transition: all .3s ease-in-out;

				@media (max-width: 991px) {
					width: calc(100% - 30px);
					max-width: 9999px;
					height: 40px;
					flex-grow: 0;
				}

				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 1;
					background-image: linear-gradient(to top, rgba(0,0,0,.86), rgba(0,0,0,.43));
				}

				&.checked {
					height: 100px;

					@media (max-width: 991px) {
						height: 40px;
						width: 100%;
					}

					&:before {
						background-image: linear-gradient(to top, rgba(0,0,0,.7), rgba(0,0,0,.07));
					}

					.before {
						height: 3px;
					}
				}
			}

			.text {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 0 5px 13px 19px;
				z-index: 4;
				font-size: rem(16);
				line-height: 18px;

				@media (max-width: 991px) {
					font-size: rem(14);
					line-height: 16px;
					padding: 0 15px;
					bottom: auto;
					top: 50%;
					transform: translateY(-50%);
				}
			}

			.before {
				position: absolute;
				left: 0;
				bottom: 0;
				height: 0;
				width: 100%;
				transition: all .3s ease-in-out;
				z-index: 3;
			}
		} /* brk-shop-grid-filter__button_style-3 */
	}
	/* BUTTONS */

	/* ITEMS */
	&-grid {
		min-height: 480px;
		@extend %bg-style;
		position: relative;
		overflow: hidden;

		&:hover {
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}

			.brk-shop-grid-filter-grid__content {
				top: 50%;
				transform: translate(-50%, -50%);
			}

			.brk-shop-grid-filter-grid__stick {
				left: -75px;
			}
		}

		&:before,
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			transition: all .3s ease-in-out;
		}

		&:before {
			background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,.2) 100%);
		}

		&:after {
			opacity: 0;
			background: rgba(#000, .7);
		}

		&__content {
			position: absolute;
			width: 100%;
			max-width: 330px;
			padding: 0 15px;
			left: 50%;
			top: calc(100% - 90px);
			transform: translateX(-50%);
			z-index: 2;
			transition: all .4s ease-in-out;

			h4 {
				min-height: 56px;
			}

			ul {
				li {
					padding: 5px 0;

					i {
						margin-top: 4px;
						margin-right: 19px;
					}
				}
			}
		}

		&__stick {
			position: absolute;
			left: 40px;
			top: 45px;
			z-index: 2;
			transition: all .5s ease-in-out;

			span {
				display: block;
				width: 72px;
				height: 72px;
				border-radius: 50%;
				position: relative;
				text-align: center;
				margin-top: -14px;
				line-height: 72px;
				text-transform: uppercase;
			}
		}

		&__buy {
			width: 100%;
			max-width: 239px;
			height: 48px;
			display: block;
			border-radius: 24px;
			position: relative;

			&-add {
				display: block;
				margin-right: 94px;
				padding-left: 25px;

				i {
					vertical-align: 0;
					margin-right: 18px;
				}
			}

			.added_to_cart {
				margin: 10px 0 0;
				padding-top: 8px;
				padding-bottom: 8px;
				font-size: rem(14);
			}

			.price {
				position: absolute;
				display: block;
				top: 50%;
				right: 3px;
				transform: translateY(-50%);
				width: 93px;
				height: 42px;
				border-radius: 21px;
			}
		}
	}

	&-cells {
		height: 0;
		padding-bottom: 100%;
		position: relative;
		overflow: hidden;

		&:hover {
			&:before,
			.before {
				height: 100%;
			}

			.brk-shop-grid-filter-cells__content {
				bottom: 0 !important;
			}

			.brk-shop-grid-filter-cells__stick {
				opacity: 1;
			}
		}

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 101%;
			height: 100%;
			transition: all .3s ease-in-out;
			z-index: 1;
			background: linear-gradient(to bottom,  rgba(0,0,0,0.27) 0%,rgba(0,0,0,0.45) 100%);
		}

		.before {
			position: absolute;
			z-index: 1;
			bottom: 0;
			left: 0;
			width: 101%;
			height: 0;
			transition: all .3s ease-in-out;
    }
    
    &__img {
      position: absolute;
      left: 0;
      top: 0;
      width: 101%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

		&__content {
			position: absolute;
			bottom: -100%;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 25px;
			z-index: 2;
			transition: all .5s ease-in-out;
		}

		/* Components */
		&__stick {
			width: 100%;
			margin: 0 -5px;
			opacity: 0;
			transition: all .3s ease-in-out;

			span {
				display: block;
				height: 20px;
				line-height: 20px;
				padding: 0 11px;
				margin: 0 5px;
				border-radius: 10px;
			}
		}

		&__rating {

			@media (min-width: 1200px) and (max-width: 1499px) {
				width: 100%;
				margin-bottom: 10px;
			}

			.brk-rating {
				margin-top: 4px;

				&__layer,
				&__imposition {
					i {
						color: var(--white);
					}
				}
			}
		}

		&__buy {
			position: relative;
			display: inline-block;
			min-width: 90px;
			height: 38px;
			border-radius: 19px;
			line-height: 38px;
			transition: all .3s ease-in-out;
			padding: 0 19px;
			text-align: center;
			border: solid 2px var(--white);

			&:hover {
				background-color: var(--white);

				.text {
					opacity: 1;
				}
			}

			.text {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				display: inline-block;
				transition: all .3s ease-in-out;
				opacity: 0;

				i {
					position: relative;
					top: -2px;
          margin-right: 3px;
          vertical-align: middle;
				}
			}
		}

		.added_to_cart {
			padding: 7px 15px;
			font-size: rem(14);
			font-weight: normal;
			margin: 10px 0;
		}
		/* Components */
	}

	&-strict {
		width: 100%;
		max-width: 370px;
		margin: 15px auto;
		position: relative;
		min-height: 470px;
		box-shadow: 0 6px 30px rgba(0,0,0,.14);
		transition: all .3s ease-in-out;
		background-color: var(--white);

		&:hover {
			transform: translate(15px, 15px);

			.before {
				opacity: 1;
			}

			.brk-shop-grid-filter-strict__back {
				transform: translate(-30px, -30px);
				opacity: 1;
				z-index: 9;
			}

			.brk-shop-grid-filter-strict__stick {
				opacity: 0;
			}
		}

		.before {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			transition: all .3s ease-in-out;
			z-index: 1;
		}

		&__back {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: 0 6px 40px rgba(0,0,0,.2);
			transition: all .3s ease-in-out;
			opacity: 0;
			z-index: -1;
			background-color: var(--white);
		}

		/* Components */
		&__thumb {
			height: 370px;
			position: relative;
			text-align: center;
			background-color: #f2f2f2;

			img {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 100%;
			}
		}

		&__inform {
			height: 100px;
			padding: 0 15px;

			.price {
				margin-top: 3px;

				.brk-shop-grid-filter-strict__back & {
					margin-top: 7px;
				}
			}

			.old-price {
				text-decoration: line-through;
				padding-right: 13px;
			}

			.brk-shop-grid-filter-strict__back & {
				border-bottom: 1px solid rgba(var(--brk-dark-base-rgb), .1);
				height: 115px;
			}
		}

		&__stick {
			position: absolute;
			right: -6px;
			top: 25px;
			width: 70px;
			height: 34px;
			font-size: rem(14);
			line-height: 34px;
			text-align: center;
			padding-left: 11px;
			transition: all .3s ease-in-out;
			z-index: 9;

			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 14% 50%);
				background-color: var(--brand-primary);
			}

			&:after {
				content: '';
				position: absolute;
				bottom: -6px;
				right: 0;
				width: 0;
				height: 0;
				border: 3px solid transparent;
				z-index: 1;
				border-left-color: var(--brk-base-3);
				border-top-color: var(--brk-base-3);
			}
		}

		&__list {
			align-items: center;
			overflow-y: auto;
			max-height: 225px;
			border-bottom: 1px solid rgba(var(--brk-dark-base-rgb), .1);

			&::-webkit-scrollbar {
				width: 7px;
			}
			&::-webkit-scrollbar-track {
				box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
				border-radius:10px;

			}
			&::-webkit-scrollbar-thumb {
				background-color: rgba(256,256,256,0.1);
				border-radius:10px;
			}

			ul,
			p {
				max-height: 100%;
				width: 100%;
				max-width: 260px;
				margin: 0 auto;
			}

			li {
				padding: 5px 0;
			}

			i {
				font-weight: 100;
				padding-top: 5px;
				padding-right: 23px;

				@media #{$xm-max} {
					padding-right: 10px;
				}
			}
		}

		&__actions {
			display: inline-block;
			border-radius: 27px;

			a {
				float: left;
				display: block;
				width: 72px;
				height: 54px;
				line-height: 48px;
				text-align: center;
				border-left: 1px solid var(--brand-primary);
        border-right: 1px solid var(--brand-primary);
        
        i{
          vertical-align: middle;
        }

				&:first-child {
					border-radius: 27px 0 0 27px;
					border: 0;
				}

				&:last-child {
					border-radius: 0 27px 27px 0;
					border: 0;
				}

				&.added_to_cart {
					font-size: rem(14);
					font-weight: normal;
					line-height: 16px;
					padding: 10px 5px 0;
					width: 72px;
					margin: 0;
					white-space: normal;
					border-radius: 0;
					background-color: #105ae0;
					color: $white;
					box-shadow: none;
				}

				&.remove_from_wishlist,
				&.remove_from_compare {
					background-color: #375cf1;
				}
			}

		}
		/* Components */
	}

	&-special {
		height: 0;
		padding-bottom: 100%;
		position: relative;
    max-height: 300px;
    
    &__overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

		&:hover {
			.brk-shop-grid-filter-special__overlay::before {
				opacity: 0;
			}
			.brk-shop-grid-filter-special__overlay::after {
				opacity: 1;
			}
			.brk-shop-grid-filter-special__content {
				top: 50%;
			}
		}

		&__overlay::before,
		&__overlay::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 101%;
			height: 100%;
			z-index: 2;
			transition: all .3s ease-in-out;
		}

		&__overlay::before {
			background-image: linear-gradient(to top, rgba(0,0,0,.69), rgba(0,0,0,0) 50%);
		}

		&__overlay::after {
			background-image: linear-gradient(to top, rgba(0,0,0,.86), rgba(0,0,0,.69) 99%);
			opacity: 0;
		}

		&__container {
			position: absolute;
			z-index: 3;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		/* Components */
		&__thumb {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: auto;
			max-width: 100%;
			height: auto;
			max-height: 100%;
			z-index: 1;
		}

		&__content {
			position: absolute;
			width: 100%;
			max-width: 250px;
			top: calc(100% + 35px);
			left: 50%;
			transform: translate(-50%, -50%);
			transition: all .4s ease-in-out;
			z-index: 1;
			
			.old-price {
				text-decoration: line-through;
				margin-right: 5px;
			}
		}

		&__title {
			min-height: 50px;
		}

		&__buy {
			display: inline-block;
			width: 179px;
			height: 44px;
			border-radius: 22px;
			position: relative;

			.text {
				display: block;
				text-align: left;
				padding-left: 31px;
			}
			
			.icon {
				position: absolute;
				top: 50%;
				right: 5px;
				transform: translateY(-50%);
				width: 36px;
				height: 36px;
				border-radius: 50%;
				line-height: 34px;
				background-color: #fdfdfd;
      }
      
      i{
        vertical-align: middle;
      }
		}

		.added_to_cart {
			padding: 5px 15px;
			font-weight: normal;
			font-size: rem(14);
			margin: 10px 0 0;
		}

		&__stick {
			position: absolute;
			top: -5px;
			right: -5px;
			width: 100px;
			height: 100px;
			z-index: 9;
			overflow: hidden;

			.stick {
				position: absolute;
				top: 0;
				right: 0;
				overflow: hidden;

				&:before,
				&:after {
					content: '';
					position: absolute;
					z-index: 1;
					width: 5px;
					height: 5px;
				}

				&:before {
					top: 0;
					left: 0;
				}

				&:after {
					right: 0;
					bottom: 0;
				}

				span {
					display: block;
					position: absolute;
					z-index: 2;
					height: 25px;
					text-align: center;
					font-size: rem(16);
					line-height: 25px;
					transform: rotate(45deg);
				}

				&:nth-child(1) {
					width: 66px;
					height: 66px;

					&:before,
					&:after {
						background-color: var(--brk-base-3);
					}

					span {
						width: 92px;
						top: -25px;
						left: 0;
						transform-origin: 0 100%;
						background-image: linear-gradient(
														to left,
														var(--brk-base-6),
														var(--brk-base-5));
					}
				}

				&:nth-child(2) {
					width: 100%;
					height: 100%;

					&:before,
					&:after {
						background-color: var(--brk-secondary-2);
					}

					span {
						width: 141px;
						top: -25px;
						left: 0;
						transform-origin: 0 100%;
						background-image: linear-gradient(
														to right,
														var(--brk-base-3),
														var(--brk-secondary-3));
					}
				}
			}
		}
		/* Components */
	}
	/* ITEMS */
}